import React, { useState } from "react"
import { Link } from "gatsby"

import { Tabs } from 'antd';

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

//Images

import { Button, Input, Modal, Tag } from "antd"

import swisskubik_003 from "../images/images/swisskubik/swisskubik_003.png"
import swisskubik_001 from "../images/images/swisskubik/swisskubik_001.jpg"
import swisskubik_002 from "../images/images/swisskubik/swisskubik_002.jpg"
import swisskubik_004 from "../images/images/swisskubik/swisskubik_004.png"
import swisskubik_005 from "../images/images/swisskubik/swisskubik_005.png"
import swisskubik_006 from "../images/images/swisskubik/swisskubik_006.png"
import swisskubik_007 from "../images/images/swisskubik/swisskubik_007.png"
import swisskubik_008 from "../images/images/swisskubik/swisskubik_008.png"
import swisskubik_009 from "../images/images/swisskubik/swisskubik_009.png"
import swisskubik_010 from "../images/images/swisskubik/swisskubik_010.png"
import swisskubik_011 from "../images/images/swisskubik/swisskubik_011.png"
import swisskubik_012 from "../images/images/swisskubik/swisskubik_012.png"
import swisskubik_013 from "../images/images/swisskubik/swisskubik_013.png"
import swisskubik_014 from "../images/images/swisskubik/swisskubik_014.png"
import swisskubik_015 from "../images/images/swisskubik/swisskubik_015.png"
import swisskubik_016 from "../images/images/swisskubik/swisskubik_016.png"
import swisskubik_017 from "../images/images/swisskubik/swisskubik_017.png"
import swisskubik_018 from "../images/images/swisskubik/swisskubik_018.png"
import swisskubik_019 from "../images/images/swisskubik/swisskubik_019.png"
import swisskubik_020 from "../images/images/swisskubik/swisskubik_020.png"
import swisskubik_021 from "../images/images/swisskubik/swisskubik_021.png"
import swisskubik_022 from "../images/images/swisskubik/swisskubik_022.png"
import swisskubik_023 from "../images/images/swisskubik/swisskubik_023.png"

const options = {
  overlayColor: "rgba(44,47,76,0.9)",
  captionStyle: {
    captionColor: "#857e74",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize",
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#857e74",
    buttonsIconColor: "rgba(176,158,111,0.8)",
  },
  autoplaySpeed: 1500,
  transitionSpeed: 0,
  showCaption: false,
  showThumbnails: false,
}

const Horlogeries_yvan_monet = () => {

  const { TabPane } = Tabs;

  //Hooks
  const [dataContact, setDataContact] = useState(false)

  //Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
      <Layout>
        <SEO title="Swisskubik"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Swisskubik</div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>


        <section>
          <div className="section-flex-01" style={{ margin: "0px" }}>

            <h2 className="h2-mobile"  style={{textAlign: 'center'}}>Swisskubik</h2>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="watch-intro" style={{ marginBottom: 0 }}>
                <img className="img" style={{ margin: "0px", borderRadius: "12px" }} src={swisskubik_003} alt="Swisskubik"/>
                <p className="p-2 p-font">
                  De la même manière, les écrins « Swisskubik » rotatifs 100 % Swiss Made sont conçus et fabriqués manuellement avec le plus grand soin pour mettre en valeur vos montres et protéger leur mouvement. Accessoires essentiels de tout amoureux d’horlogerie, nos remontoirs sont recommandés par les plus prestigieuses maisons suisses.
                </p>
              </div>
              <p className="p-2 p-font">
                En permettant aux mécanismes de vos montres de n’être jamais interrompus dans leur fonctionnement, ils sont ainsi le complément naturel qui assure leur pérennité. En effet, pour garder sa précision et prolonger sa durée de vie, une montre automatique doit toujours être en mouvement. Personnalisables à l’envie, les boîtiers‑remontoirs revêtent des parures innovantes ou classiques toujours issues des matériaux les plus nobles. Ils s’accordent avec élégance et modernité à votre montre selon sa forme, sa couleur ou la matière de son bracelet. Objets de décoration à part entière, ils vous permettent de voir vos montres fonctionner. Objets de haute précision, ils leur offrent le soin qu’elles méritent lorsque vous les déposez au coffre.
              </p>
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="container content content-gallery">
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_001} data-attribute="SRL"><img className="img-gallery" src={swisskubik_001}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_002} data-attribute="SRL"><img className="img-gallery" src={swisskubik_002}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_003} data-attribute="SRL"><img className="img-gallery" src={swisskubik_003}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_004} data-attribute="SRL"><img className="img-gallery" src={swisskubik_004}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_005} data-attribute="SRL"><img className="img-gallery" src={swisskubik_005}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_006} data-attribute="SRL"><img className="img-gallery" src={swisskubik_006}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_007} data-attribute="SRL"><img className="img-gallery" src={swisskubik_007}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_008} data-attribute="SRL"><img className="img-gallery" src={swisskubik_008}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_009} data-attribute="SRL"><img className="img-gallery" src={swisskubik_009}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_010} data-attribute="SRL"><img className="img-gallery" src={swisskubik_010}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_011} data-attribute="SRL"><img className="img-gallery" src={swisskubik_011}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_012} data-attribute="SRL"><img className="img-gallery" src={swisskubik_012}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_013} data-attribute="SRL"><img className="img-gallery" src={swisskubik_013}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_014} data-attribute="SRL"><img className="img-gallery" src={swisskubik_014}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_015} data-attribute="SRL"><img className="img-gallery" src={swisskubik_015}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_016} data-attribute="SRL"><img className="img-gallery" src={swisskubik_016}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_017} data-attribute="SRL"><img className="img-gallery" src={swisskubik_017}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_018} data-attribute="SRL"><img className="img-gallery" src={swisskubik_018}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_019} data-attribute="SRL"><img className="img-gallery" src={swisskubik_019}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_020} data-attribute="SRL"><img className="img-gallery" src={swisskubik_020}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_021} data-attribute="SRL"><img className="img-gallery" src={swisskubik_021}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_022} data-attribute="SRL"><img className="img-gallery" src={swisskubik_022}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_023} data-attribute="SRL"><img className="img-gallery" src={swisskubik_023}
                                                                         alt="Swisskubik"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={swisskubik_004} data-attribute="SRL"><img className="img-gallery" src={swisskubik_004}
                                                                         alt="Swisskubik"/></a>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
              <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Swisskubik') }>Nous contacter</Button></div>
            </div>

          </div>
        </section>

        <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
          visible={ showModalContact }
          onOk={ handleContactFormOk }
          onCancel={ handleContactFormCancel }
          okButtonProps={{ style: { display: 'none' } }}
        >

          <ContactForm page={dataContact} />

        </Modal>

      </Layout>
    </>
  )
}

export default Horlogeries_yvan_monet
